@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  outline: none;
}

.main__page {
  padding: 60px 20px;
}
input {
  padding: 0 10px;
}

.loader__block {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.loader__block img {
  width: 40px;
  height: 40px;
}

.not__found {
  text-align: center;
  margin-top: 100px;
}

.modal {
  display: flex;
  position: fixed;
  top:0;
  right:0;
  left:0;
  bottom:0;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  background-color: rgba(0,0,0,0.2);
}